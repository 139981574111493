import React, { useEffect, useState } from 'react';
import { CiSearch } from 'react-icons/ci';
import { useFiltration } from '../../../providers/FiltrationProvider';
const Search = () => {
  const { setKeyword, filters } = useFiltration();
  const [search, setSearch] = useState();
  const handleOnChange = (e) => {
    setSearch(e.target?.value.trim());
  };
  const handleSearch = () => {
    setKeyword(search);
  };
  useEffect(() => {
    if (search === '') setKeyword(undefined);
  }, [search, setKeyword]);
  return (
    <div className="flex w-full items-center rounded-md border border-gray-300 bg-white pr-2 text-xs md:w-[200px]">
      <input
        type="text"
        placeholder="Search Within"
        value={search || filters.keyword}
        className="h-full w-full rounded-[inherit] border-[inherit] bg-[inherit] p-2 outline-none"
        onChange={handleOnChange}
        autoComplete="on"
      />
      <button onClick={handleSearch}>
        <CiSearch className="shrink-0" size={16} />
      </button>
    </div>
  );
};

export default Search;
