import { useQuery } from 'react-query';
import ProductApi from '../services/ProductApi';
const INITIAL_PARAMS = {
  category: undefined,
  sup_category: undefined,
  keyword: undefined,
  page: 1,
  limit: 10,
  product_Attributes: undefined,
  stock: undefined,
  datasheet: undefined,
  sort: undefined,
};

const useProducts = (params = INITIAL_PARAMS) => {
  const { data, isLoading, isFetching, refetch } = useQuery(
    ['products', params],
    () => ProductApi.getAll({ params }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled:
        params.category === undefined && params.sup_category === undefined
          ? false
          : true,
    },
  );
  return {
    data,
    isFetching,
    isLoading,
    refetch,
  };
};

export default useProducts;
