import React, { useEffect } from 'react';

/**
 * useMediaQuery hook
 * @param {number} maxWidth - The maximum width of the screen
 * @param {number} minWidth - The minimum width of the screen
 * @returns {object} isMatched - The boolean value of the media query
 * @example
 * const { isMatched } = useMediaQuery({ maxWidth: 768 });
 * if (isMatched) {
 *  console.log('The screen is less than 768px');
 * }
 */

const useMediaQuery = ({ maxWidth, minWidth }) => {
  const [isMatched, setIsMatched] = React.useState(false);
  useEffect(() => {
    const media = window.matchMedia(
      `${minWidth ? `(min-width: ${minWidth}px)` : ''} ${minWidth && maxWidth ? 'and' : ''} ${maxWidth ? `(max-width: ${maxWidth}px)` : ''}`,
    );

    if (media.matches) {
      setIsMatched(true);
    }

    const listener = () => {
      if (!media.matches) {
        setIsMatched(false);
      } else {
        setIsMatched(true);
      }
    };

    media.addEventListener('change', listener);
    return () => media.removeEventListener('change', listener);
  }, [maxWidth, minWidth]);
  return { isMatched };
};

export default useMediaQuery;
