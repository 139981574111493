import useCategory from './useCategory';
import useProducts from './useProducts';
const INITIAL_PARAMS = {
  category: undefined,
  sup_category: undefined,
  keyword: undefined,
  page: 1,
  limit: 10,
  product_Attributes: undefined,
  stock: undefined,
  datasheet: undefined,
  sort: undefined,
};
const useCategoryProducts = (categoryId = '', params = INITIAL_PARAMS) => {
  const category = useCategory(categoryId);
  const subCategoryName = category?.subCategory?.name;
  const parentCategoryName = category?.parentCategory?.name;
  const { data, isLoading, isFetching, refetch } = useProducts({
    ...params,
    category: parentCategoryName,
    sup_category: subCategoryName,
  });
  return {
    products: data?.data?.data || [],
    categoryCount: data?.data?.categoryCount || 0,
    result: data?.data?.filteredCount || 0,
    isLoading: isLoading || isFetching,
    category: subCategoryName || parentCategoryName,
    pageCount: data?.data?.paginationResult?.numberOfPages || 1,
    refetch,
  };
};

export default useCategoryProducts;
