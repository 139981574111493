import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from '@chakra-ui/react';
import React from 'react';
import { GiSettingsKnobs } from 'react-icons/gi';
import { IoMdClose } from 'react-icons/io';
import {
  Modal,
  ModalClose,
  ModalContent,
  ModalTrigger,
} from '../../../components/Modal';
import useMediaQuery from '../../../hooks/useMediaQuery';
import {
  RadioGroup,
  RadioGroupItem,
} from '../../../components/shared/RadioGroup';
import { cn } from '../../../helpers/utils';
import { useFiltration } from '../../../providers/FiltrationProvider';
import useCategoryAttributes from '../../../hooks/useCategoryAttributes';
import { useParams } from 'react-router-dom';
import useCategoryProducts from '../../../hooks/useCategoryProducts';
import { ImSpinner9 } from 'react-icons/im';

const MobileFiltration = () => {
  const { id } = useParams();
  const { isMatched: isMobile } = useMediaQuery({ maxWidth: 768 });

  const {
    filters,
    sortBy,
    sortOrder,
    toggleAttribute,
    isAttributeSelected,
    toggleDataSheet,
    toggleStock,
  } = useFiltration();
  const {
    isLoading: isLoadingProducts,
    result,
    refetch,
  } = useCategoryProducts(id, filters);
  const { attributes, isLoading } = useCategoryAttributes(id);
  if (!isMobile) return null;
  return (
    <Modal>
      <ModalTrigger
        className="flex w-full cursor-pointer items-center justify-center rounded-full border-2 bg-white py-2 font-semibold md:hidden"
        asChild
      >
        <div className="flex items-center gap-2">
          <GiSettingsKnobs size={16} />
          <span>Filter & Sort</span>
        </div>
      </ModalTrigger>

      <ModalContent className={'flex h-full w-full flex-col gap-4 bg-white'}>
        <div className="relative flex h-14 w-full shrink-0 items-center justify-between">
          <div className="flex items-center gap-1">
            <span className="text-xs">Result:</span>
            {isLoadingProducts ? (
              <ImSpinner9 className="animate-spin" />
            ) : (
              <span className="text-lg font-bold">{result}</span>
            )}
          </div>
          <ModalClose className={'text-red-500'}>
            <IoMdClose size={24} />
          </ModalClose>
        </div>
        {isLoading ? (
          <div className="flex h-full w-full items-center justify-center">
            <p>Loading...</p>
          </div>
        ) : (
          <div className="custom-scroll flex flex-1 flex-col gap-4 overflow-auto text-xs">
            <Accordion allowMultiple className="rounded border">
              <AccordionItem className="border-none">
                <h2>
                  <AccordionButton className="border-b">
                    <Box
                      className="py-2 text-xs font-semibold"
                      as="span"
                      flex="1"
                      textAlign="left"
                    >
                      Sort By
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>

                <AccordionPanel px={4} py={2} className="border-b">
                  <RadioGroup
                    onValueChange={(value) => sortOrder(value)}
                    defaultValue={
                      filters.sort?.startsWith('-') ? 'desc' : 'asc'
                    }
                    className="flex flex-col gap-2"
                  >
                    {['asc', 'desc'].map((value) => (
                      <div className="flex items-center gap-2">
                        <RadioGroupItem
                          value={value}
                          id={value}
                          checked={
                            (filters.sort?.startsWith('-') ? 'desc' : 'asc') ===
                            value
                          }
                        />
                        <label htmlFor={value} className="capitalize">
                          {value === 'asc' ? 'Ascending' : 'Descending'}
                        </label>
                      </div>
                    ))}
                  </RadioGroup>
                </AccordionPanel>

                <RadioGroup onValueChange={(value) => sortBy(value)}>
                  {[
                    'price',
                    'Weight',
                    'CTYEA_Part_Number',
                    'Manufacturer',
                    'stock',
                    'IsQuote',
                  ].map((value) => (
                    <AccordionPanel px={4} py={2}>
                      <div className="flex items-center gap-2">
                        <RadioGroupItem
                          checked={filters?.sort?.includes(value)}
                          value={value}
                          id={value}
                        />
                        <label htmlFor={value} className="capitalize">
                          {value}
                        </label>
                      </div>
                    </AccordionPanel>
                  ))}
                </RadioGroup>
              </AccordionItem>
            </Accordion>
            <Accordion allowMultiple className="rounded border">
              <AccordionItem className="border-none">
                <h2>
                  <AccordionButton className="border-b">
                    <Box
                      className="py-2 text-xs font-semibold"
                      as="span"
                      flex="1"
                      textAlign="left"
                    >
                      Media & Stock Options
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>

                <AccordionPanel px={4} py={2} className="border-b">
                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      id="dataSheet"
                      onChange={(e) => toggleDataSheet(e.target.checked)}
                      checked={filters.datasheet}
                    />
                    <label htmlFor="dataSheet">Datasheet</label>
                  </div>
                </AccordionPanel>
                <AccordionPanel px={4} py={2} className="border-b">
                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      id="stock"
                      onChange={(e) => toggleStock(e.target.checked)}
                      checked={filters.stock}
                    />
                    <label htmlFor="stock">In Stock</label>
                  </div>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            {Object.entries(attributes || {}).map(
              ([key, values]) =>
                Array.isArray(values) && (
                  <Accordion allowMultiple className="rounded border">
                    <AccordionItem className="border-none">
                      <h2>
                        <AccordionButton className="border-b">
                          <Box
                            className="py-2 text-xs font-semibold"
                            as="span"
                            flex="1"
                            textAlign="left"
                          >
                            {key}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      {Array.isArray(values) &&
                        values.map(
                          (value) =>
                            typeof value === 'string' && (
                              <AccordionPanel p={1}>
                                <button
                                  onClick={() => {
                                    toggleAttribute(key, value);
                                  }}
                                  className={cn(
                                    'flex w-full items-start rounded p-2 text-left hover:bg-gray-100',
                                    {
                                      'bg-gray-300 hover:bg-gray-300':
                                        isAttributeSelected(key, value),
                                    },
                                  )}
                                >
                                  {value}
                                </button>
                              </AccordionPanel>
                            ),
                        )}
                    </AccordionItem>
                  </Accordion>
                ),
            )}
          </div>
        )}
        <button
          disabled={isLoadingProducts}
          className="btn btn-primary !rounded-full !px-6"
          onClick={refetch}
        >
          <span className="flex w-full items-center justify-center gap-1">
            {isLoadingProducts && (
              <ImSpinner9 className="shrink-0 animate-spin" />
            )}
            Apply Filters
          </span>
        </button>
      </ModalContent>
    </Modal>
  );
};

export default MobileFiltration;
