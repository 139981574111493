import React from 'react';
import AttributesBoxesLayout from './layouts/AttributesBoxesLayout';
import { Skeleton } from '@chakra-ui/react';
import AttributeValuesBox from './AttributeValuesBox';
import useCategoryAttributes from '../../../hooks/useCategoryAttributes';
import { useParams } from 'react-router-dom';
import useMediaQuery from '../../../hooks/useMediaQuery';

const DesktopFiltration = () => {
  const { id } = useParams();
  const { attributes, isLoading } = useCategoryAttributes(id);
  const { isMatched: isDesktop } = useMediaQuery({ minWidth: 768 });
  if (!isDesktop) return null;
  return (
    <AttributesBoxesLayout className={'hidden md:flex'}>
      {isLoading
        ? Array.from({ length: 8 }).map((_, i) => (
            <Skeleton
              key={i}
              className="shrink-0"
              height={'300px'}
              width={'200px'}
            />
          ))
        : Object.entries(attributes || {}).map(([key, values]) => (
            <AttributeValuesBox title={key} values={values} key={key} />
          ))}
    </AttributesBoxesLayout>
  );
};

export default DesktopFiltration;
