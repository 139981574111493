import {
  Badge,
  Card,
  CardBody,
  Heading,
  Image,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { FaRegFilePdf } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const ProductCard = ({ product, isLoading = false }) => {
  if (!product) return null;
  const { name, image, price, Manufacturer, _id: id } = product;
  return isLoading ? (
    <Skeleton height="200px" />
  ) : (
    <Card
      direction={{ base: 'row' }}
      overflow="hidden"
      variant="outline"
      className="flex items-center gap-2 px-2"
    >
      <div className="flex flex-col items-end gap-1 *:shrink-0">
        <Image
          objectFit="contain"
          maxW={{ base: '100px' }}
          src={image?.length > 0 ? image[0] : ''}
          // src="/img/featured_1.png"
          alt={name}
          className="ml-[4px]"
        />
        {product?.datasheet && (
          <a
            href={product.datasheet}
            className="mb-2"
            target="_blank"
            rel="noreferrer"
          >
            <FaRegFilePdf className="text-2xl text-red-500" size={20} />
          </a>
        )}
      </div>

      <Stack>
        <CardBody>
          <Heading size="sm">{name}</Heading>

          <Text py="2" size="xs">
            {Manufacturer}
          </Text>
          <div className="flex flex-wrap gap-2">
            <Link to={`/products/${id}`}>
              <Badge
                px={'10px'}
                py={'3px'}
                className="!rounded-full !text-[10px]"
                size="xs"
              >
                Details
              </Badge>
            </Link>

            <Badge
              px={'10px'}
              py={'3px'}
              className="!rounded-full !text-[10px]"
              colorScheme={'blue'}
              size={'xs'}
            >
              ${price}
            </Badge>
          </div>
        </CardBody>
      </Stack>
    </Card>
  );
};

export default ProductCard;
