import { configureStore } from '@reduxjs/toolkit';

import { productApi } from './api/productsApi';
import { authApi } from './api/authApi';
import { userApi } from './api/userApi';
import { orderApi } from './api/orderApi';
import { categoryApi } from './api/categoryApi';
import { shippingChargeApi } from './api/shippingChargeApi';

import userReducer from './features/userSlice';
import cartReducer from './features/cartSlice';
import { apiSlice } from './api/apiSlice';

export const store = configureStore({
  reducer: {
    auth: userReducer,
    cart: cartReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
    [shippingChargeApi.reducerPath]: shippingChargeApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      apiSlice.middleware,
      productApi.middleware,
      authApi.middleware,
      userApi.middleware,
      categoryApi.middleware,
      orderApi.middleware,
      shippingChargeApi.middleware,
    ]),
});
