import { CloseIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from '@chakra-ui/react';
import React from 'react';
import { useFiltration } from '../../../providers/FiltrationProvider';

const AppliedFilters = () => {
  const { removeAttribute, resetProductAttributes, filters } = useFiltration();
  const productAttributes = Object.entries(filters.product_Attributes || {});
  if (
    productAttributes.length === 0 ||
    productAttributes.every(
      ([_, value]) => value?.length === 0 || value === undefined,
    )
  )
    return null;
  return (
    <section className="flex flex-col gap-2">
      <div className="flex items-center gap-4">
        <h5 className="text-sm font-bold">APPLIED FILTERS</h5>
        <button
          className="text-xs hover:underline"
          onClick={resetProductAttributes}
        >
          Remove all
        </button>
      </div>
      <div className="flex flex-wrap items-start gap-2 *:shrink-0">
        {productAttributes.map(
          ([key, values]) =>
            values &&
            Array.isArray(values) &&
            values.length > 0 && (
              <Accordion
                allowToggle
                className="rounded border bg-white shadow-sm"
              >
                <AccordionItem className="border-none">
                  <h2>
                    <AccordionButton
                      p={0}
                      pl={4}
                      className="flex items-center border-b pr-0"
                    >
                      <Box
                        className="flex items-center gap-4 py-2 text-xs font-semibold *:shrink-0"
                        as="span"
                        flex="1"
                        textAlign="left"
                      >
                        <span>{key}</span>
                        <button
                          className="pr-4"
                          onClick={() => removeAttribute(key)}
                        >
                          <CloseIcon color={'red'} size={16} />
                        </button>
                      </Box>
                      <div className="h-full border-l px-2 py-2">
                        <AccordionIcon />
                      </div>
                    </AccordionButton>
                  </h2>

                  <AccordionPanel px={4} py={2} className="flex flex-col gap-2">
                    {values.map((value) => (
                      <div className="flex w-full items-center justify-between gap-2 text-xs">
                        <span>{value}</span>
                        <button onClick={() => removeAttribute(key, value)}>
                          <CloseIcon color={'red'} size={16} />
                        </button>
                      </div>
                    ))}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            ),
        )}
      </div>
    </section>
  );
};

export default AppliedFilters;
