import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cartItems: localStorage.getItem('cartItems')
    ? JSON.parse(localStorage.getItem('cartItems'))
    : [],
  shippingInfo: localStorage.getItem('shippingInfo')
    ? JSON.parse(localStorage.getItem('shippingInfo'))
    : {},
  paymentMethod: localStorage.getItem('paymentMethod')
    ? JSON.parse(localStorage.getItem('paymentMethod'))
    : {},
};

export const cartSlice = createSlice({
  name: 'cartSlice',
  initialState,
  reducers: {
    setCartItem: (state, action) => {
      const item = action.payload;
      const index = state.cartItems.findIndex(
        (i) => i.product === item.product,
      );
      if (index !== -1) {
        state.cartItems[index] = item; // Update the item if it exists
      } else {
        state.cartItems.push(item); // Add new item if it doesn't exist
      }
      localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
    },
    removeCartItem: (state, action) => {
      state.cartItems = state.cartItems.filter(
        (i) => i.product !== action.payload,
      );
      localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
    },
    saveShippingInfo: (state, action) => {
      state.shippingInfo = action.payload;

      localStorage.setItem('shippingInfo', JSON.stringify(state.shippingInfo));
    },

    savePaymentMethod: (state, action) => {
      state.paymentMethod = action.payload;
      localStorage.setItem(
        'paymentMethod',
        JSON.stringify(state.paymentMethod),
      );
    },
    clearCart: (state) => {
      state.cartItems = [];
      localStorage.removeItem('cartItems');
      localStorage.removeItem('shippingInfo');
      localStorage.removeItem('paymentMethod');
    },
  },
});

export const {
  setCartItem,
  removeCartItem,
  saveShippingInfo,
  savePaymentMethod,
  shippingInfo,
  clearCart,
} = cartSlice.actions;

export default cartSlice.reducer;
