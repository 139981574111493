import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Skeleton,
} from '@chakra-ui/react';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { DataTable } from '../../components/shared/DataTable';
import { cn, removeEmptyKeys } from '../../helpers/utils';
import useCategoryProducts from '../../hooks/useCategoryProducts';
import { ImSpinner9 } from 'react-icons/im';
import ReactPaginate from 'react-paginate';
import {
  FiltrationProvider,
  useFiltration,
} from '../../providers/FiltrationProvider';
import { columns } from './COLUMNS';
import AppliedFilters from './components/AppliedFilters';
import CategoryTitle from './components/CategoryTitle';
import DesktopFiltration from './components/DesktopFiltration';
import CategoryLayout from './components/layouts/CategoryLayout';
import MobileFiltration from './components/MobileFiltration';
import ProductCard from './components/ProductCard';
import Search from './components/Search';
import { ChevronRightIcon } from '@chakra-ui/icons';

const CategoryDetails = () => {
  const [, setSearchParams] = useSearchParams();
  const {
    filters,
    reset,
    setLimit,
    toggleDataSheet,
    toggleStock,
    sortByNewest,
    sortByOldest,
    setPage,
  } = useFiltration();

  const { id } = useParams();
  const {
    products,
    category,
    isLoading: isLoadingProducts,
    categoryCount,
    result,
    pageCount,
    refetch,
  } = useCategoryProducts(id, filters);
  const table = useReactTable({
    columns,
    data: products,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    rowCount: categoryCount,
  });

  //Update Search query when filters changes
  useEffect(() => {
    try {
      const product_Attributes = filters.product_Attributes
        ? JSON.stringify(filters.product_Attributes)
        : undefined;
      setSearchParams(
        removeEmptyKeys({
          ...filters,
          product_Attributes,
        }),
      );
    } catch (error) {
      setSearchParams(
        removeEmptyKeys({
          ...filters,
        }),
      );
    }
  }, [filters, setSearchParams]);
  useEffect(() => {
    if (category) {
      document.title = `${category} - Creatyea`;
    }
  }, [category]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [id, reset]);
  return (
    <CategoryLayout>
      <Breadcrumb
        separator={<ChevronRightIcon color="gray.500" />}
        className="mt-0 rounded-lg bg-white px-4 py-2 shadow-md"
      >
        <BreadcrumbItem>
          <BreadcrumbLink href="/" className="!text-blue-500">
            Home
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink href="/products" className="!text-blue-500">
            All Products
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink className="!text-gray-500" href="#">
            {category}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <CategoryTitle>
        {category ?? <Skeleton height="25px" width={'300px'} />}
      </CategoryTitle>
      <div className="flex flex-col gap-2 md:flex-row md:items-center">
        <Search />
        <div className="flex items-center gap-1">
          <span className="text-xs">Result:</span>
          {isLoadingProducts ? (
            <ImSpinner9 className="animate-spin" />
          ) : (
            <span className="text-lg font-bold">{result}</span>
          )}
        </div>
      </div>
      <DesktopFiltration />
      <MobileFiltration />
      <section className="hidden flex-col gap-4 md:flex md:flex-row md:items-center md:gap-12">
        <div className="flex w-full flex-wrap justify-between *:shrink-0 md:w-auto md:justify-normal md:gap-6">
          <div className="flex flex-col gap-2 text-xs">
            <h2 className="font-bold">Media Options</h2>
            <ul className="border-r-2 pr-6">
              <li className="flex items-center gap-2">
                <input
                  id="dataSheet"
                  type="checkbox"
                  checked={filters.datasheet}
                  onChange={(e) => toggleDataSheet(e.target.checked)}
                />
                <label htmlFor="dataSheet">Datasheet</label>
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-2 pr-6 text-xs">
            <h2 className="font-bold">Stock Options</h2>
            <ul>
              <li className="flex items-center gap-2">
                <input
                  id="stock"
                  type="checkbox"
                  checked={filters.stock}
                  onChange={(e) => toggleStock(e.target.checked)}
                />
                <label htmlFor="stock">In Stock</label>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col items-center gap-2 md:flex-row">
          <button
            onClick={refetch}
            disabled={isLoadingProducts}
            className="btn btn-primary !rounded-full !px-6"
          >
            <span className="flex w-full items-center justify-center gap-1">
              {isLoadingProducts && (
                <ImSpinner9 className="shrink-0 animate-spin" />
              )}
              Apply Filters
            </span>
          </button>
          <p className="text-md flex items-center gap-2">
            <span className="font-bold">
              {isLoadingProducts ? (
                <ImSpinner9 className="animate-spin" />
              ) : (
                result
              )}
            </span>{' '}
            of {categoryCount} Results
          </p>
        </div>
      </section>
      <AppliedFilters />
      <section className="mt-12 flex w-full flex-col gap-3">
        <div className="flex items-center justify-between gap-8 md:justify-normal">
          <div class="custom-select w-[80px]">
            <select
              value={filters.limit}
              onChange={(e) => {
                setLimit(e.target.value);
              }}
            >
              {[10, 50, 100, 200, 400].map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
          </div>

          <div class="custom-select w-[100px] md:w-[200px]">
            <select
              className="font-semibold"
              onChange={(e) => {
                if (e.target.value === 'Newest') {
                  sortByNewest();
                } else {
                  sortByOldest();
                }
              }}
            >
              <option className="font-semibold"> Newest</option>
              <option className="font-semibold"> Oldest</option>
            </select>
          </div>
        </div>
        <div
          className={cn('hidden w-full md:block', {
            'animate-pulse': isLoadingProducts,
          })}
        >
          {result === 0 && !isLoadingProducts ? (
            <h3 className="w-full text-center text-lg font-bold">Not Found</h3>
          ) : (
            <DataTable table={table} />
          )}
        </div>
        <div className="flex w-full flex-col gap-3 md:hidden">
          {result === 0 && !isLoadingProducts ? (
            <h3 className="w-full text-center text-lg font-bold">Not Found</h3>
          ) : (
            products?.map((product, index) => (
              <ProductCard
                key={index}
                product={product}
                isLoading={isLoadingProducts}
              />
            ))
          )}
        </div>
        <div className="mt-4 flex w-full items-start">
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            pageClassName="page-item aspect-square text-xs text-center flex justify-center items-center"
            pageLinkClassName="page-link rounded-full aspect-square text-xs text-center flex justify-center items-center"
            previousClassName="page-item"
            previousLinkClassName="page-link !text-xs"
            nextClassName="page-item"
            nextLinkClassName="page-link !text-xs"
            breakLabel="..."
            breakClassName="page-item rounded-full aspect-square text-xs flex justify-center items-center"
            breakLinkClassName="page-link rounded-full aspect-square flex justify-center items-center"
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={(e) => {
              if (filters.page === e.selected + 1) return;
              setPage(e.selected + 1);
            }}
            containerClassName="pagination items-center flex justify-center gap-2 flex-wrap"
            activeClassName="active"
            initialPage={filters.page - 1}
          />
        </div>
      </section>
    </CategoryLayout>
  );
};

const Wrapper = () => {
  return (
    <FiltrationProvider>
      <CategoryDetails />
    </FiltrationProvider>
  );
};

export default Wrapper;
