import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import emailjs from 'emailjs-com';
import { PREFIXES } from '../constants';

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const sendEmail = async ({ templateParams, templateNumber = 1 }) => {
  const result = await emailjs.send(
    process.env.REACT_APP_EMAILJS_SERVICE_ID,
    templateNumber === 1
      ? process.env.REACT_APP_EMAILJS_TEMPLATE_ID
      : process.env.REACT_APP_EMAILJS_TEMPLATE_ID_2,
    templateParams,
    process.env.REACT_APP_EMAILJS_USER_ID,
  );
  return result;
};

export const formatDate = (date) => {
  const myDate = new Date(date);

  const formattedDate =
    myDate.getDate() +
    '-' +
    (myDate.getMonth() + 1) +
    '-' +
    myDate.getFullYear();
  return formattedDate;
};

export const formatNumber = (value = 0, fractionDigits = 4) => {
  if (Number.isNaN(value) || typeof value === 'undefined') return value;
  value = Number(value);
  return +value.toFixed(fractionDigits);
};
/**
 * @param {string} unit
 * @returns {object}
 * @example
 *  getAllPrefixesOfUnit("ohm")
 * //=> {
 *  //  "kilo": { label: "kΩ", value: 1000 },
 *  //  "mega": { label: "MΩ", value: 1000000 },
 *  // }
 */
export const getAllPrefixesOfUnit = (unit = '') => {
  const unitsObj = {};
  Object.entries(PREFIXES).forEach(([key, value]) => {
    unitsObj[key] = { label: `${value.label}${unit}`, value: value.value };
  });
  return unitsObj;
};

/**
 * @param {object} obj
 * @param {array} keys
 * @returns {array}
 * @example
 * selectFromObject({a:1, b:2, c:3}, ['a', 'b'])
 * //=> [1, 2]
 */
export const selectFromObject = (obj = {}, keys = []) => {
  return keys.map((key) => obj[key]);
};

export const radiansToDegrees = (radians) => radians * (180 / Math.PI);

export const isSafeValue = (value) => {
  return (
    typeof value === 'number' &&
    !isNaN(value) &&
    value !== null &&
    isFinite(value)
  );
};

export function formatTime(milliseconds = 0) {
  const totalMinutes = Math.floor(milliseconds / 60000);
  const totalHours = Math.floor(totalMinutes / 60);
  const totalDays = Math.floor(totalHours / 24);
  const totalWeeks = Math.floor(totalDays / 7);
  const totalMonths = Math.floor(totalDays / 30); // Approximation: assuming 30 days in a month
  const totalYears = Math.floor(totalDays / 365); // Approximation: assuming 365 days in a year

  const minutes = totalMinutes % 60;
  const hours = totalHours % 24;
  const days = totalDays % 30;
  const weeks = totalWeeks % 4; // Approximation: assuming 4 weeks in a month
  const months = totalMonths % 12;
  return {
    hours: `${totalHours} HRS ${minutes} MIN`,
    days: `${totalDays} DAYS ${hours} HRS ${minutes} MIN`,
    weeks: `${totalWeeks} WKS ${days} DAYS ${hours} HRS ${minutes} MIN`,
    months: `${totalMonths} MOS ${weeks} WKS ${days} DAYS ${hours} HRS ${minutes} MIN`,
    years: `${totalYears} YRS ${months} MOS ${weeks} WKS ${days} DAYS ${hours} HRS ${minutes} MIN`,
  };
}

export const solveQuadraticEquation = ({ a, b, c }) => {
  const d = Math.pow(b, 2) - 4 * a * c;
  if (d < 0) throw new Error('The equation has no real roots');
  if (d === 0) return { x1: -b / (2 * a) };
  return {
    x1: (-b + Math.sqrt(d)) / (2 * a),
    x2: (-b - Math.sqrt(d)) / (2 * a),
  };
};

export const prepareProductAttributes = (productAttributes) => {
  if (!productAttributes || typeof productAttributes !== 'object')
    return undefined;
  const newObject = { $and: [] };
  Object.keys(productAttributes).forEach((key) => {
    if (Array.isArray(productAttributes[key])) {
      if (productAttributes[key].length === 0) return;
      newObject.$and.push({
        [key]: { $in: productAttributes[key] },
      });
    } else {
      newObject.$and.push({
        [key]: productAttributes[key],
      });
    }
  });
  return newObject;
};

export function getQueryParams(search) {
  const params = new URLSearchParams(search);
  const paramsObj = {};
  for (const [key, value] of params.entries()) {
    paramsObj[key] = value;
  }
  return paramsObj;
}

//Remove empty keys from object
export function removeEmptyKeys(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([_, v]) => v != null && v !== '' && v !== undefined,
    ),
  );
}

export const convertEachKeyToArray = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, [value]]),
  );
};
