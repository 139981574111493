import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setCartItem } from "../../../redux/features/cartSlice";
import { Link } from "react-router-dom";

const MiniBtnAddToCart = ({ ...product }) => {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);
     const [minQty, setMinQty] = useState(
       +product.bulk_Pricing[0]?.Qty || product.bulk_Pricing[0]?.Qty
     );

  const handleDecreaseQty = () => {
    setQuantity((prevQuantity) => Math.max(1, prevQuantity - 1));
  };

  const handleIncreaseQty = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const setItemToCart = () => {
    const cartItem = {
      product: product?._id,
      name: product?.name,
      price: product?.price,
      image: product?.image[0],
      stock: product?.stock,
      quantity,
    };

    dispatch(setCartItem(cartItem));
    toast.success("Item added to cart");
  };

  useEffect(() => {
    setQuantity(minQty);
  }, [minQty]);

  return (
    <div className="text-center">
      <div className="flex flex-col items-center">
        <span className="font-bold text-sm">
          Min Qty: <span className="font-normal text-red-600">{minQty}</span>
        </span>
        <div className="flex items-center mt-2">
          <button
            type="button"
            disabled={quantity <= minQty}
            className="bg-gray-200 text-gray-800 hover:bg-gray-300 font-bold py-1 px-2 rounded-l focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2"
            onClick={handleDecreaseQty}
          >
            -
          </button>
          <input
            type="number"
            step="1"
            value={quantity}
            onChange={(e) => setQuantity(Number(e.target.value))}
            className="w-10 text-center  border-t border-b border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            min="1"
          />
          <button
            type="button"
            className="bg-gray-200 text-gray-800 hover:bg-gray-300 font-bold py-1 px-2 rounded-r focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2"
            onClick={handleIncreaseQty}
            disabled={(product.stock === 0) | "0"}
          >
            +
          </button>
        </div>
        {(product.stock === 0) | "0" ? (
          <button
            type="button"
            className="w-20 bg-red-500 hover:bg-red-600 text-white font-bold py-2 rounded mt-2 focus:outline-none focus:ring-4 focus:ring-red-300 disabled:bg-gray-400 disabled:cursor-not-allowed"
          >
            <Link to="/get_quote" className="text-white text-sm">
              Get Quote
            </Link>
          </button>
        ) : (
          <button
            type="button"
            className="w-[80px] bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 rounded mt-2 focus:outline-none focus:ring-4 focus:ring-blue-300 disabled:bg-gray-400 disabled:cursor-not-allowed"
            onClick={setItemToCart}
          >
            <i className="fas fa-shopping-cart"></i>
          </button>
        )}
      </div>
    </div>
  );
};

export default MiniBtnAddToCart;
