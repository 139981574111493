import React from 'react';
import { useFiltration } from '../../../providers/FiltrationProvider';
import { cn } from '../../../helpers/utils';
import SortButton from './SortButton';

const Header = ({ name = '', children, className, ...props }) => {
  const { sortBy, filters } = useFiltration();
  return (
    <div
      className={cn('flex flex-col items-start gap-2 border-x pt-3', className)}
      {...props}
    >
      <span className="px-4">{children}</span>
      <div className="flex w-full">
        <SortButton
          onClick={() => sortBy(name, 'desc')}
          isActive={filters.sort === `-${name}`}
          className={'w-full'}
          type="desc"
        />
        <SortButton
          onClick={() => sortBy(name, 'asc')}
          isActive={filters.sort === name}
          className={'w-full'}
        />
      </div>
    </div>
  );
};

export default Header;
