import React, { useState } from 'react';
import { FaRegFilePdf } from 'react-icons/fa';
import { Link } from 'react-router-dom';
const MfrPartCell = ({ product }) => {
  const [failedToLoadImage, setFailedToLoadImage] = useState(false);
  if (!product) return null;
  return (
    <div className="flex items-center gap-2">
      <div className="flex items-center gap-2 *:shrink-0">
        {product?.datasheet && (
          <a href={product.datasheet} target="_blank" rel="noreferrer">
            <FaRegFilePdf className="hidden text-2xl text-red-500 md:block" />
          </a>
        )}
        <div className="peer relative size-16 cursor-pointer">
          <img
            src={product.image.length ? product.image[0] : ''}
            onError={() => setFailedToLoadImage(true)}
            alt={product.name}
          />
        </div>

        <div
          hidden={
            failedToLoadImage ||
            !product.image.length ||
            product.image[0]?.endsWith('1.jpg')
          }
          className="fixed left-[50%] top-[50%] z-[50000000] hidden size-[500px] translate-x-[-50%] translate-y-[-50%] items-center justify-center rounded-md shadow-2xl peer-hover:flex"
        >
          <img
            className="h-full w-full rounded-[inherit] object-cover"
            src={product.image.length ? product.image[0] : ''}
            alt={product.name}
          />
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <Link
          to={'/products/' + product._id}
          className="text-primary text-sm font-bold"
        >
          {product.name}
        </Link>
        <p className="text-primary/50 text-sm font-bold">
          {product.Manufacturer}
        </p>
      </div>
    </div>
  );
};

export default MfrPartCell;
