import client from '../helpers/client';

class CategoryApi {
  static getAllParentCategories(config) {
    return client.get('/allparentCategory', config);
  }
  static getParentCategoryDetails(id, config) {
    return client.get(`/parentCategory/${id}`, config);
  }

  static getParentSubCategories(id, config) {
    return client.get(`/parentCategory/${id}/subCategory`, config);
  }

  static getParentAllAttributes(id, config) {
    return client.get(`/parentCategory/${id}/attribute`, config);
  }
  static getSubCategories(config) {
    return client.get('/categories', config);
  }
  static getSubCategoryDetails(id, config) {
    return client.get(`/categories/${id}`, config);
  }
  static getAllValuesOfAttributes({ categoryName, config }) {
    const category = categoryName
      ? encodeURIComponent(categoryName)
      : undefined;
    return client.get(`/products/${category}/attributes/values`, config);
  }
}

export default CategoryApi;
