import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
const INITIAL_PARAMS = {
  category: undefined,
  sup_category: undefined,
  keyword: undefined,
  page: 1,
  limit: 10,
  product_Attributes: undefined,
  stock: undefined,
  datasheet: undefined,
  sort: undefined,
};

const FiltrationContext = createContext({
  filters: INITIAL_PARAMS,
  setFilters: () => {},
  setKeyword: (keyword) => {},
  setCategory: (category) => {},
  setSubCategory: (subCategory) => {},
  setProductAttributes: (product_Attributes) => {},
  resetProductAttributes: () => {},
  addNewAttribute: (key, value) => {},
  removeAttribute: (key, value) => {},
  isAttributeSelected: (key, value) => {},
  toggleAttribute: (key, value) => {},
  sortBy: (sort, order) => {},
  sortOrder: (order) => {},
  setLimit: (limit) => {},
  toggleDataSheet: () => {},
  toggleStock: () => {},
  sortByNewest: () => {},
  sortByOldest: () => {},
  setPage: (page) => {},
  reset: () => {},
});

const FiltrationProvider = ({ children }) => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const INITIAL_STATE = useMemo(() => {
    try {
      const product_Attributes = searchParams.get('product_Attributes')
        ? JSON.parse(decodeURIComponent(searchParams.get('product_Attributes')))
        : undefined;
      return {
        ...INITIAL_PARAMS,
        category: searchParams.get('category'),
        sup_category: searchParams.get('sup_category'),
        keyword: searchParams.get('keyword'),
        page: searchParams.get('page') || 1,
        limit: searchParams.get('limit') || 10,
        product_Attributes,
        stock: searchParams.get('stock'),
        datasheet: searchParams.get('datasheet'),
        sort: searchParams.get('sort'),
      };
    } catch (error) {
      return {
        ...INITIAL_PARAMS,
        category: searchParams.get('category'),
        sup_category: searchParams.get('sup_category'),
        keyword: searchParams.get('keyword'),
        page: searchParams.get('page') || 1,
        limit: searchParams.get('limit') || 10,
        stock: searchParams.get('stock'),
        datasheet: searchParams.get('datasheet'),
        sort: searchParams.get('sort'),
      };
    }
  }, []);
  const [filters, setFilters] = useState(INITIAL_STATE);
  const setKeyword = useCallback((keyword) => {
    setFilters((prev) => ({ ...prev, keyword }));
  }, []);
  const setCategory = useCallback((category) => {
    setFilters((prev) => ({ ...prev, category }));
  }, []);
  const setSubCategory = useCallback((subCategory) => {
    setFilters((prev) => ({ ...prev, sup_category: subCategory }));
  }, []);
  const setProductAttributes = useCallback((product_Attributes) => {
    setFilters((prev) => ({ ...prev, product_Attributes }));
  }, []);
  const addNewAttribute = useCallback((key, value) => {
    setFilters((prev) => ({
      ...prev,
      product_Attributes: {
        ...prev.product_Attributes,
        [key]: [...(prev.product_Attributes?.[key] || []), value],
      },
    }));
  }, []);
  const removeAttribute = useCallback((key, value) => {
    if (key && !value) {
      setFilters((prev) => ({
        ...prev,
        product_Attributes: {
          ...prev.product_Attributes,
          [key]: undefined,
        },
      }));
      return;
    }
    setFilters((prev) => ({
      ...prev,
      product_Attributes: {
        ...prev.product_Attributes,
        [key]: prev.product_Attributes?.[key]?.filter((val) => val !== value),
      },
    }));
  }, []);
  const reset = useCallback(() => {
    setFilters(INITIAL_STATE);
  }, []);
  const resetProductAttributes = useCallback(() => {
    setFilters((prev) => ({ ...prev, product_Attributes: undefined }));
  }, []);

  const isAttributeSelected = useCallback(
    (key, value) => {
      return filters.product_Attributes?.[key]?.includes(value) || false;
    },
    [filters?.product_Attributes],
  );
  const toggleAttribute = useCallback(
    (key, value) => {
      if (isAttributeSelected(key, value)) {
        removeAttribute(key, value);
      } else {
        addNewAttribute(key, value);
      }
    },
    [addNewAttribute, isAttributeSelected, removeAttribute],
  );

  const sortBy = useCallback((sort, order = 'asc') => {
    setFilters((prev) => ({
      ...prev,
      sort: order === 'asc' ? sort : `-${sort}`,
    }));
  }, []);

  const sortOrder = useCallback((order = 'asc') => {
    setFilters((prev) => ({
      ...prev,
      sort: order === 'asc' ? prev.sort : `-${prev.sort}`,
    }));
  }, []);
  const setLimit = useCallback((limit) => {
    setFilters((prev) => ({ ...prev, limit }));
  }, []);

  const toggleDataSheet = useCallback((checked = false) => {
    setFilters((prev) => ({
      ...prev,
      datasheet: checked ? true : undefined,
    }));
  }, []);
  const toggleStock = useCallback((checked = false) => {
    setFilters((prev) => ({
      ...prev,
      stock: checked ? true : undefined,
    }));
  }, []);
  const sortByNewest = useCallback(() => {
    setFilters((prev) => ({
      ...prev,
      sort: 'new',
    }));
  }, []);

  const sortByOldest = useCallback(() => {
    setFilters((prev) => ({
      ...prev,
      sort: 'old',
    }));
  }, []);
  const setPage = useCallback((page = 1) => {
    setFilters((prev) => ({ ...prev, page }));
  }, []);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [id, reset]);
  return (
    <FiltrationContext.Provider
      value={{
        filters,
        setFilters,
        setKeyword,
        setCategory,
        setSubCategory,
        setProductAttributes,
        resetProductAttributes,
        addNewAttribute,
        removeAttribute,
        toggleAttribute,
        isAttributeSelected,
        sortOrder,
        sortBy,
        setLimit,
        toggleDataSheet,
        toggleStock,
        sortByNewest,
        sortByOldest,
        setPage,
        reset,
      }}
    >
      {children}
    </FiltrationContext.Provider>
  );
};

const useFiltration = () => {
  const context = useContext(FiltrationContext);
  if (context === undefined) {
    throw new Error('useFiltration must be used within a FiltrationProvider');
  }
  return context;
};

export { FiltrationProvider, useFiltration };
