import { URL } from '../constants';
import { apiSlice } from './apiSlice';
import { setIsAuthenticated, setUser, setLoading } from '../features/userSlice';

export const userApi = apiSlice.injectEndpoints({
  reducerPath: 'userApi',
  endpoints: (builder) => ({
    getMe: builder.query({
      query: () => `${URL}/me`,
      transformResponse: (result) => result.user,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data));
          dispatch(setIsAuthenticated(true));
          dispatch(setLoading(false));
        } catch (error) {
          dispatch(setLoading(false));
          console.log(error);
        }
      },
      providesTags: ['User'],
    }),

    updateProfile: builder.mutation({
      query(body) {
        return {
          url: `${URL}/me/update`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['User'],
    }),
    uploadAvatar: builder.mutation({
      query(body) {
        return {
          url: `${URL}/me/upload_avatar`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['User'],
    }),
    updatePassword: builder.mutation({
      query(body) {
        return {
          url: `${URL}/password/update`,
          method: 'PUT',
          body,
        };
      },
    }),
    forgotPassword: builder.mutation({
      query(body) {
        return {
          url: `${URL}/password/forgot`,
          method: 'POST',
          body,
        };
      },
    }),
    resetPassword: builder.mutation({
      query({ token, body }) {
        return {
          url: `${URL}/password/reset/${token}`,
          method: 'PUT',
          body,
        };
      },
    }),
    getFiles: builder.query({
      query: () => `${URL}/myFiles`,
    }),
    createSubscription: builder.mutation({
      query: (body) => ({
        url: `${URL}/add/subscription`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetMeQuery,
  useUpdateProfileMutation,
  useUploadAvatarMutation,
  useUpdatePasswordMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useCreateSubscriptionMutation,
  useGetFilesQuery,
} = userApi;
