import React, { createContext, useContext, useId, useState } from 'react';

const RadioGroupContext = createContext();

const RadioGroup = ({ defaultValue, onValueChange, children, ...props }) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const groupName = useId();
  const handleChange = (value) => {
    setSelectedValue(value);
    if (onValueChange) onValueChange(value);
  };

  return (
    <RadioGroupContext.Provider
      value={{ selectedValue, handleChange, groupName }}
    >
      <div {...props}>{children}</div>
    </RadioGroupContext.Provider>
  );
};

const RadioGroupItem = ({ value, id, checked }) => {
  const { selectedValue, handleChange, groupName } =
    useContext(RadioGroupContext);

  return (
    <input
      type="radio"
      value={value}
      id={id}
      name={groupName}
      checked={checked || selectedValue === value}
      onChange={() => handleChange(value)}
    />
  );
};
export { RadioGroup, RadioGroupItem };
