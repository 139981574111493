import { URL } from '../constants';
import { apiSlice } from './apiSlice';
import { userApi } from './userApi';

export const authApi = apiSlice.injectEndpoints({
  reducerPath: 'authApi',
  endpoints: (builder) => ({
    register: builder.mutation({
      query(body) {
        return {
          url: `${URL}/register`,
          method: 'POST',
          body,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          await dispatch(userApi.endpoints.getMe.initiate(null));
        } catch (error) {
          console.log(error);
        }
      },
    }),
    login: builder.mutation({
      query(body) {
        return {
          url: `${URL}/login`,
          method: 'POST',
          body,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          await dispatch(userApi.endpoints.getMe.initiate(null));
        } catch (error) {
          console.log(error);
        }
      },
    }),
    logout: builder.query({
      query: () => `${URL}/logout`,
    }),
  }),
});

export const { useLoginMutation, useRegisterMutation, useLazyLogoutQuery } =
  authApi;
