import axios from 'axios';
const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + '/api/v1',
  withCredentials: true,
});

client.interceptors.request.use((config) => {
  return config;
});

export default client;
