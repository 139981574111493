import { URL } from '../constants';
import { apiSlice } from './apiSlice';

export const shippingChargeApi = apiSlice.injectEndpoints({
  reducerPath: 'shippingChargeApi',
  // KeepUnusedDataFor: 30,
  tagTypes: ['shippingCharge'],
  endpoints: (builder) => ({
    getShippingCharge: builder.query({
      query: () => ({
        url: `${URL}/shippingCharge`,
        method: 'GET',
      }),
      providesTags: ['shippingCharge'],
    }),
  }),
});

export const { useGetShippingChargeQuery } = shippingChargeApi;
