import { URL } from '../constants';
import { apiSlice } from './apiSlice';

export const categoryApi = apiSlice.injectEndpoints({
  reducerPath: 'categoryApi',
  // KeepUnusedDataFor: 30,
  tagTypes: ['Category'],
  endpoints: (builder) => ({
    getparentCategory: builder.query({
      query: (params) => ({
        url: `${URL}/allparentCategory`,
        method: 'GET',
        params: {
          keyword: params?.keyword,
          category: params?.category,
        },
      }),
      providesTags: ['Category'],
    }),

    getperentDetails: builder.query({
      query: (id) => ({
        url: `${URL}/parentCategory/${id}`,
        method: 'GET',
      }),
    }),

    getAllForParentSubCategory: builder.query({
      query: (id) => ({
        url: `${URL}/parentCategory/${id}/subCategory`,
        method: 'GET',
      }),
    }),

    getparentAllAtrributes: builder.query({
      query: (id) => ({
        url: `${URL}/parentCategory/${id}/attribute`,
        method: 'GET',
      }),
    }),

    getSubCategory: builder.query({
      query: (params) => ({
        url: `${URL}/categories`,
        method: 'GET',
        params: {
          keyword: params?.keyword,
          category: params?.category,
        },
      }),
    }),

    getSubCategoryDetails: builder.query({
      query: (id) => ({
        url: `${URL}/categories/${id}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetparentCategoryQuery,
  useGetSubCategoryQuery,
  useGetperentDetailsQuery,
  useGetSubCategoryDetailsQuery,
  useGetAllForParentSubCategoryQuery,
  useGetparentAllAtrributesQuery,
} = categoryApi;
