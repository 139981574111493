import { createContext, useContext, useState } from 'react';
import { cn } from '../helpers/utils';

const ModalContext = createContext();

const Modal = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <ModalContext.Provider
      value={{
        isOpen,
        open: () => setIsOpen(true),
        close: () => setIsOpen(false),
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

const ModalTrigger = ({ children, asChild = false, className, ...props }) => {
  const { open } = useModal();
  if (asChild) {
    return (
      <div onClick={open} className={className} {...props}>
        {children}
      </div>
    );
  }
  return (
    <button
      className={cn('btn btn-primary', className)}
      onClick={open}
      {...props}
    >
      {children}
    </button>
  );
};

const ModalContent = ({ children, className, ...props }) => {
  const { isOpen, close } = useModal();
  return isOpen ? (
    <div className="fixed left-0 top-0 z-[10000] flex h-screen w-screen items-center justify-center">
      <div
        className={cn('absolute z-[10000] overflow-auto bg-white p-4', className)}
        {...props}
      >
        {children}
      </div>
      <div
        className="absolute inset-0 z-[10] bg-black bg-opacity-50"
        onClick={close}
      ></div>
    </div>
  ) : null;
};

const ModalClose = ({ children, className, ...props }) => {
  const { close } = useModal();
  return (
    <button onClick={close} className={cn(className)} {...props}>
      {children}
    </button>
  );
};

const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within ModalProvider');
  }
  return context;
};
export { Modal, ModalTrigger, ModalContent, ModalClose };
