import { URL } from '../constants';
import { apiSlice } from './apiSlice';

export const orderApi = apiSlice.injectEndpoints({
  reducerPath: 'orderApi',
  tagTypes: ['Order'],
  endpoints: (builder) => ({
    createNewOrder: builder.mutation({
      query(order) {
        return {
          url: `${URL}/orders/new`,
          method: 'POST',
          body: order,
        };
      },
    }),

    myOrders: builder.query({
      query: () => `${URL}/me/orders`,
      method: 'GET',
      providesTags: ['Order'],
    }),

    orderDetails: builder.query({
      query: (id) => ({
        url: `${URL}/orders/${id}`,
        method: 'GET',
      }),
    }),

    payOrder: builder.mutation({
      query: ({ orderId, details }) => ({
        url: `${URL}/orders/${orderId}/pay`,
        method: 'PUT',
        body: JSON.stringify(details),
      }),
    }),

    getPayPalClientId: builder.query({
      query: () => ({
        url: `${URL}/config/paypal`,
        method: 'GET',
      }),
      keepUnusedDataFor: 5,
    }),
  }),
});

export const {
  useCreateNewOrderMutation,
  useMyOrdersQuery,
  usePayOrderMutation,
  useGetPayPalClientIdQuery,
  useOrderDetailsQuery,
} = orderApi;
