import React from 'react';
import { cn } from '../../../helpers/utils';

const CategoryTitle = ({ className, children, ...props }) => {
  return (
    <h1 className={cn('text-3xl font-bold', className)} {...props}>
      {children}
    </h1>
  );
};

export default CategoryTitle;
