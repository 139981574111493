import React, { lazy } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Outlet, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const queryClient = new QueryClient();
const Header = lazy(() => import('./components/layout/Header'));
const Footer = lazy(() => import('./components/layout/Footer'));
const ButtonScrollTop = lazy(
  () => import('./components/layout/Buttons/ButtonScrollTop'),
);

function App() {
  const location = useLocation();
  const hideHeaderAndFooterPaths = [
    '/order_success',
  ];
  const showHeaderAndFooter = !hideHeaderAndFooterPaths.includes(
    location.pathname,
  );

  return (
    <QueryClientProvider client={queryClient}>
      {/* Toast notifications container */}
      <ToastContainer />

      {/* Conditionally render Header */}
      {showHeaderAndFooter && <Header />}

      {/* Main content */}
      <main className="customer-main-content with-top-clearance-margin bg-[#F5F9FF] font-sans text-sm">
        <Outlet />
      </main>

      {/* Button to scroll to top */}
      <ButtonScrollTop />

      {/* Conditionally render Footer */}
      {showHeaderAndFooter && <Footer />}
    </QueryClientProvider>
  );
}

export default App;
