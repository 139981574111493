import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { flexRender } from '@tanstack/react-table';
import * as React from 'react';
import { cn } from '../../helpers/utils';

export function DataTable({ table, className, ...props }) {
  return (
    <div
      className={cn(
        'custom-scroll max-h-svh w-full overflow-auto rounded-2xl bg-white shadow-md',
        className,
      )}
      {...props}
    >
      <Table>
        <Thead className="sticky top-0 z-10 bg-[#1A1552] text-white">
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const meta = header.column.columnDef.meta;
                return (
                  <Th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    isNumeric={meta?.isNumeric}
                    className="!p-0 text-white"
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr key={row.id} className="border-b-4">
              {row.getVisibleCells().map((cell) => {
                const meta = cell.column.columnDef.meta;
                return (
                  <Td
                    key={cell.id}
                    isNumeric={meta?.isNumeric}
                    className="border-r-4"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                );
              })}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  );
}
