import { useQuery } from 'react-query';
import CategoryApi from '../services/CategoryApi';
import useCategory from './useCategory';

const useCategoryAttributes = (categoryId) => {
  const category = useCategory(categoryId);
  const categoryName =
    category?.parentCategory?.name || category?.subCategory?.name;

  const { data, isLoading } = useQuery(
    ['attributesValues', categoryName],
    () =>
      CategoryApi.getAllValuesOfAttributes({
        categoryName,
      }),
    {
      enabled: !!categoryName,
      refetchOnWindowFocus: false,
      cacheTime: 5 * 60 * 1000,
    },
  );
  return {
    isLoading,
    attributes: data?.data?.data?.category?.attributesValues,
  };
};

export default useCategoryAttributes;
