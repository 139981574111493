import React, { lazy, Suspense } from 'react';
import { ClipLoader } from 'react-spinners';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { LazyPageWrapper } from './components/OrderQuote/LazyPageWrapper/LazyPageWrapper.jsx';

// Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import App from './App';

// Custom CSS
import './App.css';

// Redux
import { Provider } from 'react-redux';
import { store } from './redux/store';

// PayPal Script
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

import { ChakraProvider } from '@chakra-ui/react';
import CategoryDetails from './pages/CategoryDetails/index.jsx';

// Pages (Lazy loading)
const Home = lazy(() => import('./pages/Home/HomePage'));
const AboutUs = lazy(() => import('./pages/About/AboutUsPage'));
const ContactUs = lazy(() => import('./pages/ContactUs/ContactUsPage'));
const GetQuote = lazy(() => import('./pages/GetQuote/GetQuotePage'));
const OdmServices = lazy(() => import('./pages/OdmServices/OdmServicesPage'));
const ProductsPage = lazy(() => import('./pages/Products/ProductsPage'));
const ProductDetails = lazy(
  () => import('./pages/Product-details/ProductDetailsPage'),
);
const NotFound = lazy(() => import('./pages/NotFoundPage'));
const ProtectedRoute = lazy(() => import('./components/ProtectedRoute'));
const Login = lazy(() => import('./pages/Auth/LoginPage'));
const Register = lazy(() => import('./pages/Auth/RegisterPage'));
const ForgotPassword = lazy(() => import('./pages/Auth/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/Auth/ResetPassword'));
const Cart = lazy(() => import('./pages/Cart/CartPage'));
const Shipping = lazy(() => import('./pages/Shipping/Shipping'));
const PaymentMethod = lazy(() => import('./pages/Payment/PaymentMethod'));
const Profile = lazy(() => import('./pages/User/MyProfile/Profile'));
const UpdateProfile = lazy(() => import('./pages/User/UpdateProfile'));
const UploadAvatar = lazy(() => import('./pages/User/UploadAvatar'));
const UpdatePassword = lazy(() => import('./pages/User/UpdatePassword'));
const PrivacyPolicy = lazy(() => import('./pages/Policy/PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('./pages/Policy/TermsConditions'));
const MyOrders = lazy(() => import('./pages/Order/MyOrders'));
const OrderDetails = lazy(() => import('./pages/Order/OrderDetails'));
const Invoice = lazy(() => import('./components/invoice/Invoice'));
const PlaceOrder = lazy(() => import('./pages/Order/ConfirmOrder'));
const OrderSuccess = lazy(() => import('./pages/Order/OrderSuccess'));
const MyList = lazy(() => import('./pages/MyList'));
const SiteMap = lazy(() => import('./components/layout/SiteMap'));

// Resources
const Resources = lazy(() => import('./pages/Resources'));
const ResourcesDetails = lazy(
  () => import('./pages/Resources/ResourcesDetails'),
);
// order quoto
const MainOrderQuoto = lazy(() => import('./pages/OrderQuote/Index.jsx'));
const CustomizationForm = lazy(
  () => import('./pages/OrderQuote/CustomizationForm/Index'),
);
const StandardCategoryForm = lazy(
  () =>
    import('./pages/OrderQuote/CustomizationForm/Catgeories/Standard/Index'),
);
const AssemplyCategoryForm = lazy(
  () =>
    import('./pages/OrderQuote/CustomizationForm/Catgeories/Assemply/Index'),
);
const OrderRequistForm = lazy(
  () => import('./pages/OrderQuote/OrderRequist/Index'),
);

// مكون التحميل المركزي
const LoadingSpinner = () => (
  <div className="flex h-screen items-center justify-center">
    <ClipLoader color="blue" size={50} />
  </div>
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route
        index
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <Home />
          </Suspense>
        }
      />
      <Route
        path="/about_us"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <AboutUs />
          </Suspense>
        }
      />
      <Route
        path="/contact_us"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <ContactUs />
          </Suspense>
        }
      />
      <Route
        path="/get_quote"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <GetQuote />
          </Suspense>
        }
      />
      <Route
        path="/odm_services"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <OdmServices />
          </Suspense>
        }
      />
      <Route
        path="/privacy_policy"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <PrivacyPolicy />
          </Suspense>
        }
      />
      <Route
        path="/terms_conditions"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <TermsAndConditions />
          </Suspense>
        }
      />
      <Route
        path="/sitemaps"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <SiteMap />
          </Suspense>
        }
      />
      <Route
        path="/products"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <ProductsPage />
          </Suspense>
        }
      />
      <Route
        path="/products/:id"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <ProductDetails />
          </Suspense>
        }
      />
      <Route path="/category/:id" element={<CategoryDetails />} />
      <Route
        path="/cart"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <Cart />
          </Suspense>
        }
      />
      <Route
        path="/login"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <Login />
          </Suspense>
        }
      />
      <Route
        path="/signup"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <Register />
          </Suspense>
        }
      />
      <Route
        path="/forgot_password"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <ForgotPassword />
          </Suspense>
        }
      />
      <Route
        path="/password/reset/:token"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <ResetPassword />
          </Suspense>
        }
      />
      <Route
        path="/order_success"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <ProtectedRoute>
              <OrderSuccess />
            </ProtectedRoute>
          </Suspense>
        }
      />

      <Route
        path="/me/myList"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <ProtectedRoute>
              <MyList />
            </ProtectedRoute>
          </Suspense>
        }
      />
      <Route
        path="/confirm_order"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <ProtectedRoute>
              <PlaceOrder />
            </ProtectedRoute>
          </Suspense>
        }
      />
      <Route
        path="/invoice/order/:id"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <ProtectedRoute>
              <Invoice />
            </ProtectedRoute>
          </Suspense>
        }
      />
      <Route
        path="/me/orders"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <ProtectedRoute>
              <MyOrders />
            </ProtectedRoute>
          </Suspense>
        }
      />
      <Route
        path="/me/order/:id"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <ProtectedRoute>
              <OrderDetails />
            </ProtectedRoute>
          </Suspense>
        }
      />
      <Route
        path="/payment_method"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <ProtectedRoute>
              <PaymentMethod />
            </ProtectedRoute>
          </Suspense>
        }
      />
      <Route
        path="/shipping"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <ProtectedRoute>
              <Shipping />
            </ProtectedRoute>
          </Suspense>
        }
      />
      <Route
        path="/me/profile"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          </Suspense>
        }
      />
      <Route
        path="/me/update_profile"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <ProtectedRoute>
              <UpdateProfile />
            </ProtectedRoute>
          </Suspense>
        }
      />
      <Route
        path="/me/upload_avatar"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <ProtectedRoute>
              <UploadAvatar />
            </ProtectedRoute>
          </Suspense>
        }
      />
      <Route
        path="/me/update_password"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <ProtectedRoute>
              <UpdatePassword />
            </ProtectedRoute>
          </Suspense>
        }
      />
      <Route
        path="/resources"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <Resources />
          </Suspense>
        }
      />
      <Route
        path="/resources/:name"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <ResourcesDetails />
          </Suspense>
        }
      />

      <Route
        path="*"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <NotFound />
          </Suspense>
        }
      />
      {/* order Quoto */}
      <Route
        path="/OrderQuote"
        element={
          <LazyPageWrapper>
            <MainOrderQuoto />
          </LazyPageWrapper>
        }
      >
        <Route
          path="request/:category"
          element={
            <LazyPageWrapper>
              <OrderRequistForm />
            </LazyPageWrapper>
          }
        />
        <Route
          index
          element={<Navigate to="/OrderQuote/customization-form" replace />}
        />
        <Route
          path="customization-form"
          element={
            <LazyPageWrapper>
              <CustomizationForm />
            </LazyPageWrapper>
          }
        >
          <Route
            index
            element={
              <LazyPageWrapper>
                <StandardCategoryForm />
              </LazyPageWrapper>
            }
          />
          <Route
            path="standard"
            element={
              <LazyPageWrapper>
                <StandardCategoryForm />
              </LazyPageWrapper>
            }
          />
          <Route
            path="assembly"
            element={
              <LazyPageWrapper>
                <AssemplyCategoryForm />
              </LazyPageWrapper>
            }
          />
        </Route>
      </Route>
    </Route>,
  ),
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PayPalScriptProvider deferLoading={true}>
        <ChakraProvider>
          <RouterProvider router={router} />
        </ChakraProvider>
      </PayPalScriptProvider>
    </Provider>
  </React.StrictMode>,
);
