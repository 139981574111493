import { URL } from '../constants';
import { apiSlice } from './apiSlice';
export const productApi = apiSlice.injectEndpoints({
  tagTypes: ['Product'],
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: (params) => ({
        url: `${URL}/products`,
        method: 'GET',
        params: {
          page: params?.page,
          keyword: params?.keyword,
          category: params?.category,
          sup_category: params?.sup_category,
          limit: params?.select,
          fields: params?.fields,
          product_Attributes: params?.product_Attributes,
        },
      }),
      providesTags: ['Product'],
    }),

    getProductDetails: builder.query({
      query: (id) => `${URL}/products/${id}`,
    }),

    getAttributesValues: builder.query({
      query: (name) => ({
        url: `${URL}/products/${name}/attributes/values`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetProductsQuery,
  useGetProductDetailsQuery,
  useGetAttributesValuesQuery,
} = productApi;
