import React from 'react';
import { cn } from '../../../../helpers/utils';

const AttributesBoxesLayout = ({ className, children, ...props }) => {
  return (
    <section
      className={cn(
        'custom-scroll flex w-full gap-2 overflow-auto py-2',
        className,
      )}
      {...props}
    >
      {children}
    </section>
  );
};

export default AttributesBoxesLayout;
