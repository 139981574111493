import { useEffect, useState } from 'react';
import { useQueries } from 'react-query';
import CategoryApi from '../services/CategoryApi';

const useCategory = (id = '') => {
  const [subCategory, setSubCategory] = useState(undefined);
  const [parentCategory, setParentCategory] = useState(undefined);
  const categoriesQueries = useQueries([
    {
      queryKey: ['subCategory', id],
      queryFn: () => CategoryApi.getSubCategoryDetails(id),
      refetchOnWindowFocus: false,
      retry: false,
    },
    {
      queryKey: ['parentCategory', id],
      queryFn: () => CategoryApi.getParentCategoryDetails(id),
      refetchOnWindowFocus: false,
      retry: false,
    },
  ]);

  //Set Categories
  useEffect(() => {
    setSubCategory(categoriesQueries[0].data?.data?.category);
    setParentCategory(categoriesQueries[1].data?.data?.parentCategory);
  }, [
    categoriesQueries[0].data?.data?.category,
    categoriesQueries[1].data?.data?.parentCategory,
  ]);

  return {
    subCategory,
    parentCategory,
  };
};

export default useCategory;
