import React from 'react';
import { cn } from '../../../../helpers/utils';

const CategoryLayout = ({ className, children, ...props }) => {
  return (
    <div className={cn('flex flex-col gap-4 px-10 pb-4', className)} {...props}>
      {children}
    </div>
  );
};

export default CategoryLayout;
