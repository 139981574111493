import client from '../helpers/client';
import { prepareProductAttributes } from '../helpers/utils';

const prepareParams = (params) => {
  const stock = params?.stock ? params.stock : undefined;
  if (stock) {
    delete params.stock;
    params['stock[gt]'] = 0;
  } else {
    delete params.stock;
  }
  const datasheet = params?.datasheet ? params.datasheet : undefined;
  if (datasheet) {
    delete params.datasheet;
    params.datasheet = true;
  } else {
    delete params.datasheet;
  }

  params.product_Attributes = prepareProductAttributes(
    params.product_Attributes,
  );
  return params;
};

class ProductApi {
  static getAll({
    params: {
      page,
      keyword,
      category,
      sup_category,
      limit,
      fields,
      product_Attributes,
      ...rest
    },
    ...config
  }) {
    const preparedParams = prepareParams({
      page,
      keyword,
      category,
      sup_category,
      limit,
      fields,
      product_Attributes,
      ...rest,
    });

    return client.get('/products', {
      params: preparedParams,
      ...config,
    });
  }
}

export default ProductApi;