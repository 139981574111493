import React from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { cn } from '../../../helpers/utils';

const SortButton = ({
  className,
  type = 'asc',
  isActive = false,
  ...props
}) => {
  return (
    <button
      className={cn(
        'flex items-center justify-center border py-1',
        {
          'opacity-50': isActive,
        },
        className,
      )}
      {...props}
    >
      {type === 'asc' ? (
        <IoIosArrowDown size={20} />
      ) : (
        <IoIosArrowDown size={20} className="rotate-180" />
      )}
    </button>
  );
};

export default SortButton;
