import React, { useState } from 'react';
import { cn } from '../../../helpers/utils';
import { useFiltration } from '../../../providers/FiltrationProvider';

const AttributeValuesBox = ({ title = '', values = [] }) => {
  const [search, setSearch] = useState('');
  const { toggleAttribute, isAttributeSelected } = useFiltration();

  const filteredValues = values.filter((value) =>
    search?.trim() ? value.toLowerCase().includes(search.toLowerCase()) : true,
  );
  if (values.some((value) => typeof value !== 'string')) return null;
  return (
    <div className="flex h-[300px] min-w-[200px] shrink-0 flex-col gap-2 rounded-md border bg-white">
      <div className="flex w-full items-center border-b p-3">
        <h4 className="text-xs font-bold">{title}</h4>
      </div>
      <div className="px-3">
        <input
          type="text"
          className="w-full rounded-md border px-2 py-1"
          placeholder="Search Filter"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <ul className="custom-scroll flex w-full flex-1 flex-col gap-2 overflow-auto px-3 pb-3">
        {filteredValues.map((value, i) =>
          typeof value === 'string' ? (
            <li
              key={i}
              className={cn(
                'cursor-pointer rounded-md px-2 py-1 text-xs hover:bg-gray-100',
                {
                  'bg-gray-200 hover:bg-gray-200': isAttributeSelected(
                    title,
                    value,
                  ),
                },
              )}
              onClick={() => toggleAttribute(title, value)}
            >
              {value}
            </li>
          ) : null,
        )}
      </ul>
    </div>
  );
};

export default AttributeValuesBox;
